import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from 'components/container/Container';


import { LandingLayout } from 'layouts'
/*
import { TweenLite } from 'gsap';
*/
import s from 'styles/pages/privacy-policy.scss';

const menuItems = [
	{
		button: false,
		name: 'Go back',
		to: '/register-now',
		anchor: false,
	},
]

export default () => {
	return(
	  <LandingLayout menuItems={menuItems}>
	    <Helmet>
            <title>Terms and Conditions - Hair, Beauty, Fittnes and Aesthetics</title>
            <meta name="description" content="Take control of your hair and beauty business. Growing your beauty business can be made easier with JustMe." />
		    <script>
		        {
				  `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				  n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
				  n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
				  t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
				  document,'script','https://connect.facebook.net/en_US/fbevents.js');
				  fbq('init', ${process.env.FACEBOOK_PIXEL_ID}); 
				  fbq('track', 'PageView');`
		        }
		    </script>
		    <noscript>
		    {
		    	`<img height="1" width="1" style="display:none"
				  src="https://www.facebook.com/tr?id=${process.env.FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"
				/>`
			}
		    </noscript>

	    </Helmet>



	    <div className={`${s.sectionlanding}`}>
	    	<Container>
	    		<div className={s.container_doc}>
	    				<h1 className={s.title__subtitle}>Terms and Conditions</h1>


<ol className={s.list_decimal}>
  <li>
    <strong>General Information</strong>
    <ol className={s.list_decimal}>
      	<li>Just Me Associates Ltd (company number 11592470) (<strong>Just Me</strong>) is the operator of the Just Me website, app and other services (<strong>Just Me Services</strong>).</li>
      	<li>These terms of use (which are deemed to include our <a href="/privacy-policy" style={{ textDecoration: 'underline'}}>privacy policy</a> (<strong>Privacy Policy</strong>)) govern the rights, obligations and conduct of all users (<strong>Customers</strong>) when using the Just Me Services.</li>
      	<li>Just Me connects individual consumers (<strong>Consumers</strong>) to businesses (<strong>Partners</strong>) who wish to provide their services (<strong>Partner Services</strong>) to Consumers. The Partners are able to use the Just Me Services to connect with Consumers who have requested the Partner Services.</li>
    	<li>By using the Just Me Services, you (whether a Consumer or a Partner) hereby agree to these terms and conditions.</li>
    	<li>Just Me shall be appointed to act as the Partners’ agent to conclude contracts for the provision of Partner Services by the Partner to the Consumer (<strong>Partner Services Contracts</strong>.</li>
    	<li>These terms do not govern any contractual relationships which are entered into directly between Consumers and Partners, and Just Me shall not be a party to any such relationship.</li>
    	<li>Just Me only uses any personal data we collect through your use of the Just Me Services in accordance with, and in the ways set out in, our Privacy Policy.</li>
    	<li>You are solely responsible for your interactions with other users of the Just Me Services. Just Me does not conduct criminal background or other background checks on its users. Just Me makes no representations or warranties as to the conduct of users and is in no way responsible for conduct of users.</li>
    	<li>Just Me reserves the right to conduct any criminal background check or other screenings (such as sex offender register searches) at any time using available public records.</li>
    	<li>Just Me does not guarantee that the Just Me Services will always be available or be uninterrupted. Just Me may suspend or withdraw or restrict the availability of all or any part of the Just Me Services for business and operational reasons.</li>
    	<li>By using the Just Me Services, you agree to us collecting and using technical information about the devices on which you use the Just Me Services and related software, hardware and peripherals to improve our products and to provide any Just Me Services to you.</li>
    </ol>
  </li>
  <li>
  	<strong>Registration for Consumers and Partners</strong>
    <ol className={s.list_decimal}>
    	<li>The use of the Just Me Services requires you to register and create an account: a Consumer account for Consumers and a Partner account for Partners. Just Me is entitled in its absolute discretion to reject or cancel your registration at any time without giving reasons.</li>
    	<li>You hereby warrant that all contact details and other information provided by you during the Just Me registration process is complete and accurate in all respects. </li>
    	<li>After successful registration, you are entitled to use the Just Me Services under these terms.</li>
    	<li>During the registration process, you are asked to provide access data, such as your phone number, email address, username and password. Using this access data, you can sign in to use the Just Me Services after the activation of your registration. </li>
    	<li>It is your responsibility to ensure that your chosen username does not violate or infringe any third-party rights (including intellectual property rights), and does not otherwise fail to comply with these terms. Just Me reserves the right to reject any opposed username or require you to change it at any time.</li>
    	<li>It is your responsibility to ensure that your access data is kept secret and treated as confidential. You must not disclose it to any third party. Just Me reserves the right to disable any access data at any time.</li>
    	<li>If you know or suspect that anyone other than you knows or has access to your access data, you must promptly notify us at <a href="mailto:info@justme.net" style={{ textDecoration: 'underline'}}>info@justme.net</a>.</li>
    </ol>
  </li>
  <li>
  	<strong>Booking and Delivering Partner Services</strong>
    <ol className={s.list_decimal}>
    	<li>The Just Me Services enable Consumers and Partners to view the profiles of each other in accordance with these terms. Such profiles will include the information you provide to Just Me (including your name, username, data, pictures, etc.).</li>
    	<li>Just Me acts solely as a platform under which Consumers may contact Partners with a view to purchasing Partner Services from them. Just Me is not responsible for any Partner Services Contracts which are concluded using the Just Me Services, and will in no circumstances be a party to such contracts.</li>
    	<li>The Partner’s acceptance of a Consumer’s order will take place when Just Me emails you to accept it (<strong>Email Confirmation</strong>), at which point a Partner Services Contract will come into existence between the Consumer and the Partner.</li>
    	<li>The Email Confirmation will include any of the Partner’s terms and conditions that apply to the Partner Services Contract, including the date and time of the Partner Services to be performed (<strong>Appointment</strong>).</li>
    	<li>The Partner appoints Just Me as its agent for the purposes of concluding Partner Services Contract on behalf of the Partner for the supply of the Partner Services by the Partner to Consumers.</li>
    	<li>The Partner authorises Just Me to negotiate and conclude Partner Services Contracts in the name of and on behalf of the Partner, without prior reference to the Partner, but only through the Just Me Services.</li>
    	<li>Any descriptions or illustrations contained on the Just Me Services are issued or published for the. sole purpose of giving an approximate idea of the Partner Services described in them. They shall not form part of the Partner Services Contract or have any contractual force.</li>
    	<li>Consumers have legal rights in relation to services that are not performed with reasonable care and skill or are otherwise not as described. Nothing in these Booking Terms and Conditions will affect these legal rights.</li>
    	<li>Just Me has the right to remove any Consumer or Partner’s membership if, in Just Me’s opinion, their use of the Just Me Services does not comply with these terms, or for any other reasonable reason.</li>

    </ol>
  </li>

  <li>
  	<strong>Price/Payment</strong>
    <ol className={s.list_decimal}>
    	<li>All prices paid under Partner Services Contract shall be at the Partner’s list prices or rates as specified in the price lists provided from time to time by the Partner to Just Me or the Consumer for this purpose, subject to any discounts or deductions as the Partner may allow.</li>
    	<li>Just Me makes every effort to ensure that pricing is fully up to date and correct at the time a Consumer places an order, however occasionally it may make errors in such pricing. If, after an Email Confirmation has been sent, Just Me becomes aware that the price agreed is not the correct price that was specified by the Partner, it will notify both the Consumer and the Partner, and either the Consumer or the Partner shall be entitled to terminate the Partner Services Contract on giving notice.</li>
    	<li>The Consumer must pay the price for the Partner Services at the time of placing its order. Unless stated otherwise, payment will be in pounds sterling and will include any applicable VAT.</li>

    </ol>
  </li>

  <li>
  	<strong>Cancellations</strong>
    <ol className={s.list_decimal}>
    	<li>In the event the Consumer cancels an Appointment at less than 24 hours’ notice or does not attend an Appointment, Just Me shall be entitled to retain the Booking Fee.</li>\
    	<li>In the event the Consumer cancels an Appoint at more than 24 hours’ notice, Just Me will return the Booking Fee to the Consumer.</li>
    	<li>Upon the occurrence of a cancellation pursuant to clause 5.1, Just Me shall pay to the Partner an amount equal to 50% of the Booking Fee from the cancelled Appointment within 14 days of the cancellation.</li>
    </ol>
  </li>


  <li>
  	<strong>Partner Booking Fee and Commission</strong>
    <ol className={s.list_decimal}>
    	<li>Just Me shall be entitled to a Booking Fee (as defined in clause 6.2 below) if a Partner Services Contract is entered into between the Consumer and the Partner.</li>
    	<li>The amount of the Booking Fee payable shall be at the rate of 20% of the price paid by the Consumer under each Partner Services Contract and any future contracts pursuant to clause 6.4 (<strong>Booking Fee</strong>).</li>
    	<li>The Booking Fee under the Partner Services Contracts will be made to, and processed by, Just Me, and the balance of the the total price charged to the Consumer will be paid directly by the Consumer to the Partner upon attending the Appointment.</li>
    	<li>If the Partner enters into a Partner Services Contract with the Consumer, Just Me shall be entitled to a commission at the rate of 20% of the price paid by the Consumer (<strong>Commission</strong>) on all future services provided by the Partner to the Consumer, whether booked using the Just Me Services or otherwise. In such instances, the Partner shall pay such Commission to Just Me within 14 days of receipt of payment from the Consumer.</li>
    	<li>The Partner shall keep separate accounts and records giving correct and adequate details of all enquiries received and transactions conducted with Consumers, both using the Just Me Services and otherwise, including bookings, invoices and receipts, and shall permit the duly appointed representatives of Just Me at all reasonable times to inspect all those accounts and records and to take copies of them.</li>
    	<li>For the duration of the period that it uses the Just Me Services, the Partner shall maintain adequate professional liability insurance with a reputable insurer and shall promptly provide a copy of the insurance policy and proof of payment of the current premium to Just Me on request.</li>
    </ol>
  </li>

  <li>
  	<strong>Content</strong>
    <ol className={s.list_decimal}>
    	<strong>Just Me Content</strong>
    	<li>Subject to clause 7.3 below, the Just Me Services, all content on the Just Me Services and all features available within the Just Me Services (“<strong>Just Me Content</strong>”) are owned by Just Me or its licensors, and all rights subsisting in them (including intellectual property rights) are owned by or licensed to Just Me.</li>
    	<li>Consumers and Partners have no intellectual property rights in, or to, the Just Me Content, other than the right to use it to the extent necessary to access and utilise the Just Me Services in accordance with these terms. </li>
    	<li>Consumers and Partners must not modify the Just Me Content in any way, and must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</li>
    	<li>Just Me’s status (and that of any identified contributors) as the authors of Just Me Content must always be acknowledged.</li>
    	<li>
    		Consumers and Partners must not use any part of the Just Me Content (including contact details of other users) for any of the following purposes:
    		<ol className={s.list_decimal}>
    			<li>to create or transmit spam messages, marketing or advertising;</li>
    			<li>to post or transmit any communication designed or intended to obtain access data from, or other information about, other users or third parties;</li>
    			<li>to send any offers, applications or other communications relating to contests, sweepstakes, advertisements or pyramid schemes; or</li>
    			<li>to send any direct marketing, or to collect any information to use or to pass to third parties for the purposes of direct marketing.</li>
    		</ol>
    	</li>
    	<li>If Consumers or Partners print off, copy or download any part of the Just Me Content in breach of these terms, their right to use the Just Me Services will cease immediately and they must, at Just Me’s option, return or destroy any copies of the Just Me Content that they have made.</li>
    	<strong>User Content:</strong>
    	<li>
    		Some features of the Just Me Services allow users to upload or distribute content (including creating your username and/or password) (“<strong>User Content</strong>”). Consumers and Partners must ensure that any User Content that they upload, distribute or create, and any Partner Services they provide through, or advertise on, the Just Me Services:
    		<ol className={s.list_decimal}>
    			<li>are owned by, or licensed to, them and that they have the rights to upload them and to grant to third parties the rights set out in these terms;</li>
    			<li>do not infringe the intellectual property rights of any third party;</li>
    			<li>do not violate any applicable laws, including rights to privacy and data protection laws; </li>
    			<li>are not sexually explicit;</li>
    			<li>are accurate and not misleading or deceptive;</li>
    			<li>are not fraudulent or illegal or unlawful in any way;</li>
    			<li>are not defamatory, obscene, offensive, hateful or inflammatory;</li>
    			<li>do not promote violence, discrimination or any other illegal activity; and</li>
    			<li>are not threatening, abusive or harassing of any other user or third party,</li>
    			<p>and Consumers and Partners will be liable to and indemnify Just Me for any breach of this clause 7.7.</p>
    		</ol>
    	</li>
    	<li>Any User Content that Consumers or Partners upload or create will be considered non-confidential and non-proprietary. Consumers and Partners retain all of their ownership rights in such User Content, but hereby to grant Just Me and other users of the Partner Services a limited licence to use, store and copy that User Content to the extent necessary to provide the Just Me Services and in accordance with these terms and the Privacy Policy.</li>
    	<li>Just Me has the right to remove any Consumer or Partner’s membership and/or any posting they make on the Just Me Services if, in Just Me’s opinion, their use of the Just Me Services and/or their post does not comply with clause 7.7 above, or for any other reasonable reason.</li>
    	<li>Just Me does not verify or approve, or check for accuracy or legality of, any User Content (including descriptions of Partner Services) posted onto the Just Me Services by users (including Partners) and assumes no responsibility for the completeness, accuracy, legality or timeliness of such User Content.</li>
    	<li>Where the Just Me Services contain links to other sites and resources provided by third parties or Partners, these links are provided for users’ information only. Such links should not be interpreted as approval by Just Me of those linked websites or information obtained from them.</li>
    	<li>Partners hereby grant to Just Me a non-exclusive licence to use and display their name, logo, trademarks and any other intellectual property rights owned by them for the purpose of displaying and promoting them on the Just Me Services.</li>


    </ol>
  </li>
  <li>
  	<strong>Improper Use of Just Me Services</strong>
  	<ol className={s.list_decimal}>
  		<li>
  			Consumers and Partners must not:
  			<ol className={s.list_decimal}>
  				<li>use the Just Me Services in a manner which is in breach of any of the restrictions set out in clause 7.5 above;</li>
  				<li>use the Just Me Services in any manner which constitutes, or in any way promotes, illegal or unlawful activity;</li>
  				<li>introduce to Just Me, the Just Me Services, other users or any third parties any viruses, trojans, malware, worms, logic bombs or other material that is malicious or technologically harmful;</li>
  				<li>attack Just Me, the Just Me Services, other users or any third parties using a denial-of-service attack or a distributed denial-of service attack;</li>
  				<li>sub-license, provide, or otherwise make available, the Just Me Services in any form, in whole or in part, to any person without prior written consent from Just Me;</li>
  				<li>disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the Just Me Services, nor attempt to do any such things; or</li>
  				<li>alter, manipulate, circumvent, overcharge or disrupt the Just Me Services or their underlying software or security systems.</li>
  			</ol>
  		</li>
  		<li>If there is a suspicion of unlawful acts being carried out by a Consumer or Partner pursuant to their use of the Just Me Services, Just Me is entitled to review their activities and, if necessary, initiate appropriate legal action to prevent, report or otherwise pursue legal action in relation to the unlawful act.</li>
  		<li>If a Consumer or Partner becomes aware of any illegal, abusive, or otherwise unauthorized use of Just Me Services, they must promptly contact <a href="mailto:info@justme.net" style={{textDecoration: 'underline'}}>info@justme.net</a> with relevant details.</li>
  	</ol>
  </li>
  <li>
  	<strong>Warranty</strong>
  	<ol className={s.list_decimal}>
  		<li>
  			Just Me provides a connection service between Consumers and Partners and is in no way liable for Partner Services provided by Partners. In particular, Just Me is not responsible for, and has no liability in relation to:
  			<ol className={s.list_decimal}>
  				<li>the provision of (or failure to provide) any Partner Services, or the performance of (or failure to perform) any contracts, agreed between the Consumers and Partners;</li>
  				<li>the satisfactory provision or quality of any Partner Services agreed between Consumers and Partners.</li>
  			</ol>
  		</li>
  		<li>Just Me Services are aimed exclusively at individuals/ persons over the age of 16 years and by signing up to the Just Me Services you hereby guarantee and warrant that you are at least 16 years old. Just Me will not accept any liability if you register for the Just Me Services and you are under the age of 16. </li>
  		<li>If you suspect or become aware that any user is under 16 years of age, you must report this to Just Me immediately by email to <a href="mailto:info@justme.net" style={{textDecoration: 'underline'}}>info@justme.net</a>, stating the relevant profile and reasons for suspicion.</li>
  		<li>Just Me reserves the right to demand proof of age from any Customer and is entitled to suspend your account until proof of age is provided.</li>



  	</ol>
  </li>
  <li>
  	<strong>Blocking of access</strong>
  	<ol className={s.list_decimal}>
  		<li>Just Me reserves the right to suspend or cancel any Consumer or Partner’s access to the Just Me Services at any time and will not be liable for any loss arising as a result of such suspension or cancellation.</li>
  		<li>In the case of suspension or cancellation, Just Me will block access then notify the Consumer or Partner via email. If access is subsequently reactivated, the Consumer or Partner will be notified of such reactivation by email.</li>
  		<li>If access is cancelled, the Consumer or Partner may not seek to register a new account at any time.</li>
  		<li>Just Me reserves the right to cancel any account if it discovers or reasonably suspects that the Consumer or Partner has previously had their account cancelled and has created a new account using different or falsified registration details.</li>



  	</ol>
  </li>
  <li>
  	<strong>Liability and Indemnity</strong>
  	<ol className={s.list_decimal}>
  		<li>Just Me makes every effort to provide a high-quality, consistent service in collaboration with its third party service providers. Potential failures due to malfunctions or maintenance must be expected. </li>
  		<li>Just Me cannot guarantee that the use of the Just Me Services will be uninterrupted or error-free.</li>
  		<li>Just Me gives no guarantee that the Just Me Services will be available at any period. Just Me will not be liable for any loss arising as a result of the Just Me Services being interrupted.</li>
  		<li>Just Me does not guarantee that the Just Me Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access the Just Me Services. You should use your own virus protection software.</li>
  		<li>If Just Me fails to comply with these terms, it is responsible for loss or damage Consumers or Partners suffer that is a foreseeable result of Just Me breaking these terms or failing to use reasonable care and skill, but it is not responsible for any loss or damage that is not foreseeable.</li>
  		<li>Just Me does not exclude or limit in any way its liability to Consumers or Partners where it would be unlawful to do so, including for death or personal injury caused by Just Me’s negligence or the negligence of its employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</li>
  		<li>Just Me will have no liability to Partners for any indirect loss, consequential loss, loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
  		<li>The Just Me Services have not been developed to meet your individual requirements. Consumers and Partners must check that the facilities and functions of the Just Me Services meet their requirements.</li>
  		<li>If the provision of the Just Me Services or support for the Just Me Services is prevented or delayed by an event outside Just Me’s control then it will take steps to minimise the effect of the delay. Provided it does this then it will not be liable for delays caused by the event.</li>
  		<li>Partners and Consumers hereby indemnify Just Me against all liabilities, costs, expenses, damages and losses (including but not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis) and all other professional costs and expenses) suffered or incurred by Just Me arising out of or in connection with:
  			<ol className={s.list_decimal}>
  				<li>any breach of these terms by the Partner or Consumer; and</li>
  				<li>any damage such Consumer or Partner causes to other users when attending at meetings or appointments with such other users.</li>
  			</ol>
  		</li>
  		<li>The Partner hereby indemnifies Just Me against all liabilities, costs, expenses, damages and losses (including but not limited to any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis) and all other professional costs and expenses) suffered or incurred by Just Me arising out of or in connection with the Partner’s provision of the Partner Services to any Consumer.</li>

  	</ol>
  </li>
  <li>
  	<strong>General</strong>
  	<ol className={s.list_decimal}>
  		<li>Just Me may update and change the Just Me Services from time to time to reflect changes to Just Me’s product, user needs and Just Me’s business priorities.</li>
  		<li>Just Me reserves the right to change these terms and/or the Privacy Policy at any time. Every time Consumers or Partners wish to use the Just Me Services, they should check these terms to ensure they understand the terms that apply at that time.</li>
  		<li>Just Me may transfer our rights and obligations under these terms to a third party. Consumers and Partners may only transfer their rights or obligations under these terms to another person if Just Me agrees in writing in advance.</li>
  		<li>These terms do not give rise to any rights to enforce any part of these terms under the Contracts (Rights of Third Parties) Act 1999.</li>
  		<li>A waiver of any right or remedy under these terms or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent right or remedy.</li>
  		<li>A failure or delay by a party to exercise any right or remedy provided under these terms or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under these terms or by law shall prevent or restrict the further exercise of that or any other right or remedy.</li>
  		<li>Should any provision of these terms be or become invalid, the legal validity of the remaining provisions shall remain unaffected.</li>
  		<li>These terms and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with them or their subject matter or formation, shall be governed by and construed in accordance with the law of England.</li>
  		<li>Each party irrevocably agrees that the courts of England shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with these terms or their subject matter or formation.</li>

  	</ol>
  </li>


</ol>



		    	</div>
		    </Container>
	    </div>



	    <div className={s.footer}>
	    	<Container>
		    	<div className={s.footer__flex}>
		    		<div className={s.footer__col}>
		    			<ul className={s.footer__social}>
		    				<li>
		    					<a target="_blank" rel="noopener" href="https://www.facebook.com/justmeforbeauty">
									<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
										<title>Facebook</title>
										<path d="M19.32924,46V25.92955h-6.75413v-7.82187h6.75413v-5.76839C19.32924,5.64511,23.41782,2,29.38949,2 c2.86049,0,5.31894,0.21297,6.03539,0.30816v6.99581l-4.14167,0.00188c-3.24773,0-3.87657,1.54327-3.87657,3.80792v4.99391h7.74553 l-1.0085,7.82187h-6.73704V46H19.32924z"/>
									</svg>
		    					</a>
		    				</li>
		    				{
		    					/*
			    				<li>
			    					<a target="_blank" rel="noopener" href="">
										<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
											<title>Twitter</title>
											<path d="M48,9.1c-1.8,0.8-3.7,1.3-5.7,1.6c2-1.2,3.6-3.1,4.3-5.4c-1.9,1.1-4,1.9-6.3,2.4c-1.8-1.9-4.4-3.1-7.2-3.1 c-5.4,0-9.8,4.4-9.8,9.8c0,0.8,0.1,1.5,0.3,2.2C15.5,16.2,8.2,12.3,3.3,6.3C2.5,7.8,2,9.4,2,11.2c0,3.4,1.7,6.4,4.4,8.2 c-1.6-0.1-3.1-0.5-4.5-1.2c0,0,0,0.1,0,0.1c0,4.8,3.4,8.8,7.9,9.7c-0.8,0.2-1.7,0.3-2.6,0.3c-0.6,0-1.3-0.1-1.9-0.2 c1.3,3.9,4.9,6.8,9.2,6.8c-3.4,2.6-7.6,4.2-12.2,4.2c-0.8,0-1.6,0-2.3-0.1c4.4,2.8,9.5,4.4,15.1,4.4c18.1,0,28-15,28-28 c0-0.4,0-0.9,0-1.3C45,12.8,46.7,11.1,48,9.1z"></path>									
										</svg>				
			    					</a>
			    				</li>

		    					*/
		    				}
		    				<li>
		    					<a target="_blank" rel="noopener" href="https://www.instagram.com/justme4justyou/">
									<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
										<title>Instagram</title>
										<path d="M24,4.324c6.408,0,7.167,0.024,9.698,0.14c2.731,0.125,5.266,0.672,7.216,2.622 c1.95,1.95,2.497,4.485,2.622,7.216c0.115,2.531,0.14,3.29,0.14,9.698s-0.024,7.167-0.14,9.698 c-0.125,2.731-0.672,5.266-2.622,7.216c-1.95,1.95-4.485,2.497-7.216,2.622c-2.53,0.115-3.289,0.14-9.698,0.14 s-7.168-0.024-9.698-0.14c-2.731-0.125-5.266-0.672-7.216-2.622c-1.95-1.95-2.497-4.485-2.622-7.216 c-0.115-2.531-0.14-3.29-0.14-9.698s0.024-7.167,0.14-9.698c0.125-2.731,0.672-5.266,2.622-7.216c1.95-1.95,4.485-2.497,7.216-2.622 C16.833,4.349,17.592,4.324,24,4.324 M24,0c-6.518,0-7.335,0.028-9.895,0.144c-3.9,0.178-7.326,1.133-10.077,3.884 c-2.75,2.75-3.706,6.175-3.884,10.077C0.028,16.665,0,17.482,0,24c0,6.518,0.028,7.335,0.144,9.895 c0.178,3.9,1.133,7.326,3.884,10.077c2.75,2.75,6.175,3.706,10.077,3.884C16.665,47.972,17.482,48,24,48s7.335-0.028,9.895-0.144 c3.899-0.178,7.326-1.133,10.077-3.884c2.75-2.75,3.706-6.175,3.884-10.077C47.972,31.335,48,30.518,48,24s-0.028-7.335-0.144-9.895 c-0.178-3.9-1.133-7.326-3.884-10.077c-2.75-2.75-6.175-3.706-10.077-3.884C31.335,0.028,30.518,0,24,0L24,0z"></path> <path d="M24,11.676c-6.807,0-12.324,5.518-12.324,12.324S17.193,36.324,24,36.324 S36.324,30.807,36.324,24S30.807,11.676,24,11.676z M24,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S28.418,32,24,32z"></path> <circle cx="36.811" cy="11.189" r="2.88"></circle>
									</svg>
		    					</a>
		    				</li>
		    				{
			    				/*
			    				<li>

			    					<a target="_blank" rel="noopener" href="">
										<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
											<title>LinkedIn</title>
											<path d="M46,0H2C0.9,0,0,0.9,0,2v44c0,1.1,0.9,2,2,2h44c1.1,0,2-0.9,2-2V2C48,0.9,47.1,0,46,0z M14.2,40.9H7.1V18 h7.1V40.9z M10.7,14.9c-2.3,0-4.1-1.8-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C14.8,13,13,14.9,10.7,14.9z M40.9,40.9 h-7.1V29.8c0-2.7,0-6.1-3.7-6.1c-3.7,0-4.3,2.9-4.3,5.9v11.3h-7.1V18h6.8v3.1h0.1c0.9-1.8,3.3-3.7,6.7-3.7c7.2,0,8.5,4.7,8.5,10.9 V40.9z"/>
										</svg>
			    					</a>
			    				</li>
			    				*/


		    				}

		    			</ul>
		    		</div>


		    		<div className={s.footer__col}>
		    		{
		    			/*
		    			<div className={s.footer__appcontainer}>
		    			{ data.applebadge &&
						    <Img
						    	className={s.footer__appbadge}
						      	fluid={data.applebadge.childImageSharp.fluid}
						    />
		    			}
		    			</div>
		    			<div className={s.footer__appcontainer}>
		    			{ data.googlebadge &&
						    <Img
						    	className={s.footer__appbadge}
						      	fluid={data.googlebadge.childImageSharp.fluid}
						    />
		    			}
		    			</div>

		    			*/
		    		}


		    		</div>
		    	</div>
		    	<div className={`${s.footer__flex} ${s.footer__subfooter}`}>
	    			<div className={s.footer__subfooter__credits}>
	    				All rights reserved &copy;  { new Date().getFullYear() }
	    			</div>
	    			<div className={s.footer__subfooter__copyright}>
	    				Site by <a href="https://vital.agency/" rel="noopener" target="_blank" className={s.footer__subfooter__copyright__link}>Vital Agency</a>
	    			</div>
		    	</div>
	    	</Container>
	    </div>

	  </LandingLayout>
	)
};

